exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-dostawa-index-tsx": () => import("./../../../src/pages/dostawa/index.tsx" /* webpackChunkName: "component---src-pages-dostawa-index-tsx" */),
  "component---src-pages-dziekujemy-index-tsx": () => import("./../../../src/pages/dziekujemy/index.tsx" /* webpackChunkName: "component---src-pages-dziekujemy-index-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-kontakt-index-tsx": () => import("./../../../src/pages/kontakt/index.tsx" /* webpackChunkName: "component---src-pages-kontakt-index-tsx" */),
  "component---src-pages-manage-index-tsx": () => import("./../../../src/pages/manage/index.tsx" /* webpackChunkName: "component---src-pages-manage-index-tsx" */),
  "component---src-pages-menu-chirashi-[id]-index-tsx": () => import("./../../../src/pages/menu/chirashi/[id]/index.tsx" /* webpackChunkName: "component---src-pages-menu-chirashi-[id]-index-tsx" */),
  "component---src-pages-menu-chirashi-index-tsx": () => import("./../../../src/pages/menu/chirashi/index.tsx" /* webpackChunkName: "component---src-pages-menu-chirashi-index-tsx" */),
  "component---src-pages-menu-dzieci-[id]-index-tsx": () => import("./../../../src/pages/menu/dzieci/[id]/index.tsx" /* webpackChunkName: "component---src-pages-menu-dzieci-[id]-index-tsx" */),
  "component---src-pages-menu-dzieci-index-tsx": () => import("./../../../src/pages/menu/dzieci/index.tsx" /* webpackChunkName: "component---src-pages-menu-dzieci-index-tsx" */),
  "component---src-pages-menu-futomaki-[id]-index-tsx": () => import("./../../../src/pages/menu/futomaki/[id]/index.tsx" /* webpackChunkName: "component---src-pages-menu-futomaki-[id]-index-tsx" */),
  "component---src-pages-menu-futomaki-index-tsx": () => import("./../../../src/pages/menu/futomaki/index.tsx" /* webpackChunkName: "component---src-pages-menu-futomaki-index-tsx" */),
  "component---src-pages-menu-hosomaki-[id]-index-tsx": () => import("./../../../src/pages/menu/hosomaki/[id]/index.tsx" /* webpackChunkName: "component---src-pages-menu-hosomaki-[id]-index-tsx" */),
  "component---src-pages-menu-hosomaki-index-tsx": () => import("./../../../src/pages/menu/hosomaki/index.tsx" /* webpackChunkName: "component---src-pages-menu-hosomaki-index-tsx" */),
  "component---src-pages-menu-lunch-[id]-index-tsx": () => import("./../../../src/pages/menu/lunch/[id]/index.tsx" /* webpackChunkName: "component---src-pages-menu-lunch-[id]-index-tsx" */),
  "component---src-pages-menu-lunch-index-tsx": () => import("./../../../src/pages/menu/lunch/index.tsx" /* webpackChunkName: "component---src-pages-menu-lunch-index-tsx" */),
  "component---src-pages-menu-napoje-[id]-index-tsx": () => import("./../../../src/pages/menu/napoje/[id]/index.tsx" /* webpackChunkName: "component---src-pages-menu-napoje-[id]-index-tsx" */),
  "component---src-pages-menu-napoje-index-tsx": () => import("./../../../src/pages/menu/napoje/index.tsx" /* webpackChunkName: "component---src-pages-menu-napoje-index-tsx" */),
  "component---src-pages-menu-nigiri-[id]-index-tsx": () => import("./../../../src/pages/menu/nigiri/[id]/index.tsx" /* webpackChunkName: "component---src-pages-menu-nigiri-[id]-index-tsx" */),
  "component---src-pages-menu-nigiri-index-tsx": () => import("./../../../src/pages/menu/nigiri/index.tsx" /* webpackChunkName: "component---src-pages-menu-nigiri-index-tsx" */),
  "component---src-pages-menu-sashimi-[id]-index-tsx": () => import("./../../../src/pages/menu/sashimi/[id]/index.tsx" /* webpackChunkName: "component---src-pages-menu-sashimi-[id]-index-tsx" */),
  "component---src-pages-menu-sashimi-index-tsx": () => import("./../../../src/pages/menu/sashimi/index.tsx" /* webpackChunkName: "component---src-pages-menu-sashimi-index-tsx" */),
  "component---src-pages-menu-uramaki-[id]-index-tsx": () => import("./../../../src/pages/menu/uramaki/[id]/index.tsx" /* webpackChunkName: "component---src-pages-menu-uramaki-[id]-index-tsx" */),
  "component---src-pages-menu-uramaki-index-tsx": () => import("./../../../src/pages/menu/uramaki/index.tsx" /* webpackChunkName: "component---src-pages-menu-uramaki-index-tsx" */),
  "component---src-pages-menu-wege-[id]-index-tsx": () => import("./../../../src/pages/menu/wege/[id]/index.tsx" /* webpackChunkName: "component---src-pages-menu-wege-[id]-index-tsx" */),
  "component---src-pages-menu-wege-index-tsx": () => import("./../../../src/pages/menu/wege/index.tsx" /* webpackChunkName: "component---src-pages-menu-wege-index-tsx" */),
  "component---src-pages-menu-zestawy-[id]-index-tsx": () => import("./../../../src/pages/menu/zestawy/[id]/index.tsx" /* webpackChunkName: "component---src-pages-menu-zestawy-[id]-index-tsx" */),
  "component---src-pages-menu-zestawy-index-tsx": () => import("./../../../src/pages/menu/zestawy/index.tsx" /* webpackChunkName: "component---src-pages-menu-zestawy-index-tsx" */),
  "component---src-pages-o-nas-index-tsx": () => import("./../../../src/pages/o_nas/index.tsx" /* webpackChunkName: "component---src-pages-o-nas-index-tsx" */),
  "component---src-pages-restauracja-index-tsx": () => import("./../../../src/pages/restauracja/index.tsx" /* webpackChunkName: "component---src-pages-restauracja-index-tsx" */),
  "component---src-pages-zamowienie-index-tsx": () => import("./../../../src/pages/zamowienie/index.tsx" /* webpackChunkName: "component---src-pages-zamowienie-index-tsx" */)
}

