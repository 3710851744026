import React, {createContext, ReactNode, useCallback, useContext, useEffect, useState} from 'react';
import {closeSiteToday, isSiteClosed, openSiteToday, resetOverrides} from "../api/api";
import {useAuth} from "./auth.context";
import {useNotificationContext} from "./notification.context";

interface SiteProps {
    isClosed: boolean;
    checkIfSiteClosed: () => void;
    closeSite: () => void;
    openSite: () => void;
    resetOpening: () => void;
}

const SiteContext = createContext<SiteProps | null>(null);

export const useSiteContext = () => {
    const context = useContext(SiteContext);
    if (!context) {
        throw new Error('useSiteContext must be used within a SiteProvider');
    }
    return context;
};

const SiteProvider: React.FC<{ children: ReactNode }> = ({children}) => {
    const [isClosed, setIsClosed] = useState(false);

    const {token, isLoggedIn, logout} = useAuth();
    const {addNotification} = useNotificationContext();

    const checkIfSiteClosed = useCallback(async () => {
        const isClosed = await isSiteClosed();

        setIsClosed(Boolean(isClosed));
    }, [])

    const openSite = useCallback(async () => {
        if (!isLoggedIn) {
            return;
        }

        openSiteToday(token).catch((err) => {
            addNotification('Wystąpił błąd');
        });

        setTimeout(() => checkIfSiteClosed(), 1000);
    }, [token, isLoggedIn, checkIfSiteClosed])

    const closeSite = useCallback(async () => {
        if (!isLoggedIn) {
            return;
        }

        closeSiteToday(token).catch(() => {
            addNotification('Wystąpił błąd');
        });

        setTimeout(() => checkIfSiteClosed(), 1000);
    }, [token, isLoggedIn, checkIfSiteClosed])

    useEffect(() => {
        checkIfSiteClosed()
    }, []);

    const resetOpening = useCallback(async () => {
        if (!isLoggedIn) {
            return;
        }

        resetOverrides(token).catch(() => {
            addNotification('Wystąpił błąd');
        });

        setTimeout(() => checkIfSiteClosed(), 1000);
    }, [token, isLoggedIn, checkIfSiteClosed])

    return (
        <SiteContext.Provider
            value={{
                isClosed,
                checkIfSiteClosed,
                openSite,
                closeSite,
                resetOpening
            }}>
            {children}
        </SiteContext.Provider>
    );
};

export default SiteProvider;
