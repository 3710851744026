import React, { createContext, ReactNode, useContext, useEffect, useState } from 'react';
import debounce from 'lodash.debounce';

interface WindowSizeContextProps {
  windowWidth: number;
  windowHeight: number;
}

const WindowSizeContext = createContext<WindowSizeContextProps>({
  windowWidth: typeof window !== 'undefined' ? window.innerWidth : 0,
  windowHeight: typeof window !== 'undefined' ? window.innerHeight : 0,
});

export const useWindowSize = () => useContext(WindowSizeContext);

export const WindowSizeProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [windowSize, setWindowSize] = useState({
    windowWidth: typeof window !== 'undefined' ? window.innerWidth : 0,
    windowHeight: typeof window !== 'undefined' ? window.innerHeight : 0,
  });

  useEffect(() => {
    const handleResize = debounce(() => {
      setWindowSize({
        windowWidth: typeof window !== 'undefined' ? window.innerWidth : 0,
        windowHeight: typeof window !== 'undefined' ? window.innerHeight : 0,
      });
    }, 200);
    if (typeof window !== 'undefined') {
      window.addEventListener('resize', handleResize);
    }


    return () => {
      if (typeof window !== 'undefined') {
        window.removeEventListener('resize', handleResize);
      }

    };
  }, []);

  return (
    <WindowSizeContext.Provider
      value={{ ...windowSize }}
    >
      {children}
    </WindowSizeContext.Provider>
  );
};
