import React, { FC, ReactNode } from 'react';
import * as styles from './limited-width-layout.module.scss';

interface LimitedWidthLayoutProps {
  children: ReactNode;
  limitedWidthSize?: number;
  stretch?: boolean;
}


const LimitedWidthLayout: FC<LimitedWidthLayoutProps> = ({ children, limitedWidthSize, stretch }) => {
  // const container = useRef<HTMLElement>(null);
  //
  // useLayoutEffect(() => {
  //   if (container?.current) {
  //     container.current.style.maxWidth = `${limitedWidthSize}px`;
  //   }
  // }, []);

  return (
    <div className={`${styles.LimitedWidthLayout} ${stretch ? styles.stretch : ''}`}>
      <div className={styles.container} style={{ maxWidth: limitedWidthSize ? `${limitedWidthSize}px` : '1500px' }}>
        {children}
      </div>
    </div>
  );
};

export default LimitedWidthLayout;
