import React, {FC, ReactNode, useEffect, useState} from 'react';
import * as styles from './layout.module.scss';
import NavBar from './nav-bar/nav-bar';
import {createTheme, ThemeProvider} from '@mui/material';
import MainScrollableContent from '../scrollable/mainScrollableContent';
import Footer from './footer/footer';
import CloseSiteDialog from "../close-site-dialog/close-site-dialog";
import NotificationContainer from "../notification/notificationContainer";

interface LayoutProps {
    children: ReactNode;
    navBarBg?: boolean;
    fixedNavBar?: boolean;
    setBgColor?: boolean;
    limitedWidthSize?: number;
    stretchVertical?: boolean;
}

const Layout: FC<LayoutProps> = ({
                                     children,
                                     fixedNavBar,
                                     limitedWidthSize,
                                 }) => {
    const theme = createTheme(
        {
            palette: {
                mode: 'dark',
                primary: {
                    main: '#ff9b74',
                },
                secondary: {
                    main: '#d6cfca'
                }
            },
            components: {
                // Name of the component
                MuiButton: {
                    styleOverrides: {
                        // Name of the slot
                        root: ({ownerState}) => ({
                            fontSize: '1.6rem',
                            fontWeight: '400',
                            borderRadius: '12px',
                            letterSpacing: '0.1rem',
                            fontFamily: 'Roboto, sans-serif',
                            textTransform: 'none',
                            '&.MuiButton-outlined': {
                                backgroundColor: '#2a2b2d',
                                borderWidth: '2px'
                            }
                        }),
                    },
                },
                MuiDialogContent: {
                    styleOverrides: {
                        root: () => ({
                            '.MuiPickersCalendarHeader-labelContainer': {
                                fontSize: '1.6rem',
                            },
                            '.MuiTypography-root': {
                                fontSize: '1.6rem'
                            },
                            '.MuiButtonBase-root': {
                                fontSize: '1.6rem'
                            }, '.MuiSvgIcon-root': {
                                fontSize: '2.2rem'
                            }, '.MuiClockNumber-root': {
                                fontSize: '1.4rem'
                            },
                        })

                    }
                }
                // MuiTextField: {
                //     styleOverrides: {
                //         root: ({ownerState}) => ({
                //             fontSize: '2rem',
                //             backgroundColor: '#2a2b2d'
                //         }),
                //     }
                // },
                // MuiInput: {
                //     styleOverrides: {
                //
                //     }
                // }
            },
        },
    );

    const [showContent, setShowContent] = useState(false);


    useEffect(() => {
        setShowContent(true);
    }, []);

    return (
        <ThemeProvider theme={theme}>
            <div
                className={`${styles.Layout} ${showContent ? styles.initialized : ''}`}>
                <NavBar limitedWidthSize={limitedWidthSize} fixed={fixedNavBar}></NavBar>
                {children}
                <CloseSiteDialog/>
                <NotificationContainer/>
            </div>
        </ThemeProvider>
    );
};

export default Layout;
