import React, {FC} from 'react';
import * as styles from './cart-icon.module.scss';
import {useCartContext} from "../../../shared/context/cart.context";
import {Link} from "gatsby";

interface CartProps {
}

const CartIcon: FC<CartProps> = () => {
    const {productsCount} = useCartContext();

    return (
        <Link to="/zamowienie" className={styles.Cart}>
            <svg width="30" height="24" viewBox="0 0 30 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M10.1078 8V6C10.1078 5.46957 10.3185 4.96086 10.6936 4.58579C11.0686 4.21071 11.5774 4 12.1078 4H18.1078C18.6382 4 19.1469 4.21071 19.522 4.58579C19.8971 4.96086 20.1078 5.46957 20.1078 6V8"
                    stroke="#D6CFCA" strokeWidth="1.5"/>
                <path
                    d="M7.6158 20H22.5998C22.7418 20.0001 22.8792 19.9497 22.9876 19.858C23.096 19.7663 23.1684 19.6391 23.1918 19.499L24.9918 8.699C25.0062 8.61301 25.0017 8.52491 24.9785 8.44084C24.9554 8.35678 24.9142 8.27876 24.8579 8.21223C24.8015 8.1457 24.7314 8.09224 24.6522 8.05559C24.5731 8.01894 24.487 7.99997 24.3998 8H5.8158C5.72861 7.99997 5.64246 8.01894 5.56335 8.05559C5.48424 8.09224 5.41407 8.1457 5.35772 8.21223C5.30137 8.27876 5.2602 8.35678 5.23707 8.44084C5.21394 8.52491 5.20941 8.61301 5.2238 8.699L7.0238 19.499C7.04723 19.6391 7.11958 19.7663 7.22798 19.858C7.33637 19.9497 7.47379 20.0001 7.6158 20Z"
                    stroke="#D6CFCA" strokeWidth="1.5"/>
            </svg>
            {productsCount !== 0 && <div className={styles.cartCount}>{productsCount}</div>}
        </Link>
    )
};

export default CartIcon;
