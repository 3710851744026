// fetch-api.ts

export async function get<T>(url: string, headers?: { [key: string]: string }, token?: string): Promise<T> {
    const response = await fetch(url, {
        method: 'GET',
        headers: headers,
    });
    if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
    }
    return await response.json();
}

export async function post<T>(url: string, data: any, headers?: { [key: string]: string }): Promise<T> {
    const response = await fetch(url, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            ...headers,
        },
        body: JSON.stringify(data),
    });
    if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
    }
    return await response.json();
}


export function addTokenToHeaders(token: string, headers: { [key: string]: string } = {}) {
    if (token) {
        if (!headers) {
            headers = {};
        }
        headers.Authorization = `Bearer ${token}`;
    }

    return headers;
}


