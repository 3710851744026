import {addTokenToHeaders, get, post} from "../utils/fetch-api";
import {OrderDTO} from "../data/types";

interface OrderResponse {
    url: string;
    message: string;
    status: number;
}

export enum PromoCodeType {
    PERCENTAGE = 'PERCENTAGE',
    FIXED = 'FIXED'
}

export interface PromoCode {
    valid: boolean;
    value: number;
    type: PromoCodeType;
    message: string;
    codeText: string;
}

export function isSiteClosed(): Promise<string> {
    return get<string>('/api/utils/isSiteClosed');
}

export function order(data: OrderDTO): Promise<OrderResponse> {
    return post<OrderResponse>('/api/order', data)
}

export function checkPromoCode(promoCode: string): Promise<PromoCode> {
    return post<PromoCode>('/api/utils/checkPromoCode', {promoCode: promoCode})
}

export function loginApi(data: { username: string; password: string; }): Promise<{ token: string }> {
    return post<{ token: string }>('/api/login', data);
}

export function openSiteToday(token: string): Promise<void> {
    const headers = addTokenToHeaders(token);
    return post<void>('/api/utils/openToday',{}, headers);
}

export function resetOverrides(token: string): Promise<void> {
    const headers = addTokenToHeaders(token);
    return post<void>('/api/utils/resetOverrides',{}, headers);
}

export function closeSiteToday(token: string): Promise<void> {
    const headers = addTokenToHeaders(token);
    return post<void>('/api/utils/closeToday', {}, headers);
}

export function getHashed(data: { toHash: string }): Promise<string> {
    return post<string>('/api/getHashed', data);
}