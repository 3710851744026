import React, {FC, useEffect, useMemo} from 'react';
import * as styles from './notification.module.scss';
import {useNotificationContext} from "../../shared/context/notification.context";
import NotificationAlert from "./notification-alert/notification-alert";


interface Props {
}

const NotificationContainer: FC<Props> = () => {

    const {notifications} = useNotificationContext();


    return (
        <div className={`${styles.container}`}>
            {notifications.map((item) => <NotificationAlert key={item.id} notification={item}/>)}
        </div>
    );
};

export default NotificationContainer;
