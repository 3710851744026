import React, {FC, useEffect, useState} from 'react';
import * as styles from './notification-alert.module.scss';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import {NotificationItem, useNotificationContext} from "../../../shared/context/notification.context";


interface Props {
    notification: NotificationItem;
}

const NotificationAlert: FC<Props> = ({notification}) => {
    const [show, setShow] = useState(false);
    const [hide, setHide] = useState(false);

    const {removeAllNotifications} = useNotificationContext();

    useEffect(() => {
        const timeout1 = setTimeout(() => setShow(true), 200);
        const timeout2 = setTimeout(() => setHide(true), 10000);

        return () => {
            clearTimeout(timeout1);
            clearTimeout(timeout2);
        }
    }, []);

    useEffect(() => {
        const handleDocumentClick = () => {
            removeAllNotifications();
        };

        if (show) {
            document.addEventListener('click', handleDocumentClick);
        }


        return () => {
            document.removeEventListener('click', handleDocumentClick);
        };
    }, [show]);

    return (
        <div className={`${styles.container} ${show ? styles.show : ''} ${hide ? styles.hide : ''}`}>

            <div className={`${styles.content} ${styles[notification.type]}`}>
                {notification.info}

                <ErrorOutlineIcon className={styles.errorIcon}/>
            </div>
        </div>
    );
};

export default NotificationAlert;
