import React from 'react';
import {WindowSizeProvider} from './src/shared/context/window-size.context';
import DeviceProvider from './src/shared/context/device.context';
import CartProvider from "./src/shared/context/cart.context";
import {WrapPageElementBrowserArgs} from "gatsby";
import Layout from "./src/components/layout/layout";
import {LocalizationProvider} from "@mui/x-date-pickers";
import {AdapterDateFns} from "@mui/x-date-pickers/AdapterDateFnsV3";
import SiteProvider from "./src/shared/context/site-state.context";
import NotificationProvider from "./src/shared/context/notification.context";
import AuthProvider from "./src/shared/context/auth.context";

interface WrapRootElementArgs {
    element: React.ReactNode;
}

export const wrapRootElement = ({element}: WrapRootElementArgs) => {

    return (
        <LocalizationProvider dateAdapter={AdapterDateFns}>
            <CartProvider>
                <WindowSizeProvider>
                    <NotificationProvider>
                        <AuthProvider>
                            <SiteProvider>
                                <DeviceProvider>

                                    {element}

                                </DeviceProvider>
                            </SiteProvider>
                        </AuthProvider>
                    </NotificationProvider>
                </WindowSizeProvider>
            </CartProvider>
        </LocalizationProvider>
    )
};

export const wrapPageElement = ({element}: WrapPageElementBrowserArgs) => {

    return (
        <main>
            <Layout limitedWidthSize={1920} navBarBg={true} setBgColor={true} stretchVertical={true}>
                {element}
            </Layout>
        </main>
    )
};