import React, {createContext, ReactNode, useCallback, useContext, useMemo, useState} from 'react';
import {loginApi} from "../api/api";
import {useNotificationContext} from "./notification.context";

interface AuthProps {
    isLoggedIn: boolean;
    token: string;
    error: boolean;
    login: (username: string, password: string) => Promise<void>;
    logout: () => void;
}

const AuthContext = createContext<AuthProps | null>(null);

export const useAuth = () => {
    const context = useContext(AuthContext);
    if (!context) {
        throw new Error('useAuth must be used within an AuthProvider');
    }
    return context;
};

const AuthProvider: React.FC<{ children: ReactNode }> = ({children}) => {
    const [token, setToken] = useState('');
    const [error, setError] = useState(false);

    const {addNotification} = useNotificationContext();

    const login = useCallback(async (username: string, password: string) => {
        setToken('');

        const res = await loginApi({username, password}).catch((err) => {
            setError(true);
            addNotification('Błędne dane');
        });

        if (res?.token) {
            setError(false);
            const {token} = res;
            setToken(token);
        }
    }, [setError, setToken, addNotification]);

    const logout = useCallback(() => {
        setToken('');
        // You may want to clear authentication tokens or perform other cleanup tasks here
    }, []);

    const isLoggedIn = useMemo(() => !!token, [token]);

    // useEffect(() => {
    //     // Check if user is already logged in on component mount
    //     const checkAuthorization = async () => {
    //         try {
    //             const isAuthorized = await checkSecure();
    //             setIsLoggedIn(isAuthorized);
    //         } catch (error) {
    //             // Handle authorization check error
    //             console.error('Authorization check failed:', error);
    //             setIsLoggedIn(false);
    //         }
    //     };
    //
    //     checkAuthorization();
    // }, []);

    return (
        <AuthContext.Provider
            value={{
                isLoggedIn,
                login,
                logout,
                token,
                error
            }}>
            {children}
        </AuthContext.Provider>
    );
};

export default AuthProvider;
