// extracted by mini-css-extract-plugin
export var NavBar = "nav-bar-module--NavBar--73d3a";
export var active = "nav-bar-module--active--ae969";
export var fixed = "nav-bar-module--fixed--78ffe";
export var headerBottom = "nav-bar-module--header-bottom--3b037";
export var headerLocation = "nav-bar-module--headerLocation--4985c";
export var headerTop = "nav-bar-module--header-top--4afc5";
export var headerTopContainer = "nav-bar-module--header-top-container--3a7b3";
export var logo = "nav-bar-module--logo--b0112";
export var logoImage = "nav-bar-module--logo-image--cda27";
export var mainContainer = "nav-bar-module--main-container--bd92e";
export var menuIcon = "nav-bar-module--menu-icon--16b8c";
export var mobileBgHelper = "nav-bar-module--mobile-bg-helper--f6d1d";
export var navBtn = "nav-bar-module--nav-btn--6f436";
export var navContainer = "nav-bar-module--nav-container--02dda";
export var phone = "nav-bar-module--phone--ade21";
export var ppNav = "nav-bar-module--pp-nav--3a5f8";
export var sidebar = "nav-bar-module--sidebar--7e02b";
export var sidebarContainer = "nav-bar-module--sidebar-container--2004f";
export var sidebarItems = "nav-bar-module--sidebar-items--4ac85";
export var sidebarTransparentBg = "nav-bar-module--sidebar-transparent-bg--b8244";