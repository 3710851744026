import React, {createContext, ReactNode, useCallback, useContext, useState} from 'react';

export enum NotificationType {
    ALERT = 'ALERT',
    INFO = 'INFO'
}
export interface NotificationItem {
    id: number;
    info: string;
    type: string;
}

interface NotificationProps {
    notifications: NotificationItem[];
    addNotification: (info: string, type?: NotificationType) => void;
    removeNotification: (id: number) => void;
    removeAllNotifications: () => void;
}

const NotificationContext = createContext<NotificationProps | null>(null);

export const useNotificationContext = () => {
    const context = useContext(NotificationContext);
    if (!context) {
        throw new Error('useNotificationContext must be used within a NotificationProvider');
    }
    return context;
};

const NotificationProvider: React.FC<{ children: ReactNode }> = ({children}) => {
    const [notifications, setNotifications] = useState<NotificationItem[]>([]);

    const addNotification = useCallback((info: string, type = NotificationType.ALERT) => {
        setNotifications(curr => [...curr, {id: new Date().getTime(), info, type}]);
    }, [setNotifications]);

    const removeNotification = useCallback((id: number) => {

        const notificationsNew = notifications.filter(item => item.id !== id);

        setNotifications(notificationsNew);
    }, [setNotifications, notifications])

    const removeAllNotifications = useCallback( () => {
        setNotifications([]);
    }, [setNotifications])

    return (
        <NotificationContext.Provider
            value={{
                notifications,
                addNotification,
                removeNotification,
                removeAllNotifications
            }}>
            {children}
        </NotificationContext.Provider>
    );
};

export default NotificationProvider;
