import React, {FC, useEffect, useState} from 'react';
import * as styles from './close-site-dialog.module.scss';
import {IconButton} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import {useSiteContext} from "../../shared/context/site-state.context";


interface Props {
}

const CloseSiteDialog: FC<Props> = () => {
    const [showDialog, setShowDialog] = useState(false);
    const {isClosed} = useSiteContext();

    useEffect(() => {
        setShowDialog(isClosed);

    }, [isClosed]);

    return (
        <div className={`${styles.closeSiteContainer} ${showDialog && styles.closeSiteVisible}`} onClick={() => setShowDialog(false)}>

            <div className={styles.closeSiteContent}>
                <IconButton className={styles.closeBtn} onClick={() => setShowDialog(false)}><CloseIcon
                    className={styles.closeIcon}/></IconButton>
                <h3>
                    Restauracja w tej chwili
                </h3>
                <h1>
                    jest zamknięta
                </h1>

                <span>
                   Zapraszamy w godzinach otwarcia :)
                </span>

            </div>
        </div>
    );
};

export default CloseSiteDialog;
