import React, {createContext, ReactNode, useCallback, useContext, useEffect, useMemo, useState} from 'react';
import {getProductById} from "../data/data.utils";
import {CartProduct, DeliveryOptions} from "../data/types";
import {PromoCode, PromoCodeType} from "../api/api";


const deliveryPriceMapper = {
    [DeliveryOptions.SELF]: 0,
    [DeliveryOptions.COURIER]: 9.99
}

interface CartContextProps {
    products: CartProduct[];
    productsCount: number;
    productsPrice: number;
    discountedProductsPrice: number;
    deliveryPrice: number;
    discount: number;
    discountData: PromoCode | undefined;
    summary: number;
    addProduct: (productId: string, count: number) => boolean;
    addSingleProduct: (productId: string) => boolean;
    deleteProduct: (productId: string) => boolean;
    setDeliveryType: (type: DeliveryOptions) => void;
    setPromoCode: (code?: PromoCode) => void;
    clearCart: () => void;
}

const addProductsToLocalStorage = (products: CartProduct[]) => {
    if (typeof window === 'undefined') {
        return;
    }

    localStorage.setItem('products', JSON.stringify(products));
}

const getProductsFromLocalStorage = (): CartProduct[] => {
    if (typeof window === 'undefined') {
        return [];
    }

    const products = localStorage.getItem('products');

    return products ? JSON.parse(products) : []
}

const CartContext = createContext<CartContextProps | null>(null);

export const useCartContext = () => {
    const context = useContext(CartContext);
    if (!context) {
        throw new Error('useCartContext must be used within a CartProvider');
    }
    return context;
};

const CartProvider: React.FC<{ children: ReactNode }> = ({children}) => {
    const [products, setProducts] = useState<CartProduct[]>([]);
    const [deliveryPrice, setDeliveryPrice] = useState(deliveryPriceMapper[DeliveryOptions.COURIER]);
    const [discountData, setDiscountData] = useState<PromoCode>();

    const clearCart = useCallback(() => {
        setProducts([]);
        addProductsToLocalStorage([]);
    }, [setProducts]);

    const addProduct = useMemo(() => (productId: string, count: number) => {
        if (productId == null) {
            return false;
        }

        const productIdx = products.findIndex((item: CartProduct) => item.productId === productId);

        if (productIdx >= 0) {
            products[productIdx].count = count;

            setProducts([...products]);
            addProductsToLocalStorage(products);
            return true;
        }

        const uniqueProducts = [...new Set([...products, {productId, count}])]

        setProducts(uniqueProducts);
        addProductsToLocalStorage(uniqueProducts);
        return true;

    }, [products])

    const addSingleProduct = useMemo(() => (productId: string) => {
        if (!productId) {
            return false;
        }

        const productIdx = products.findIndex((item: CartProduct) => item.productId === productId);

        if (productIdx >= 0) {
            products[productIdx].count += 1;

            setProducts([...products]);
            addProductsToLocalStorage(products);
            return true;
        }

        const newProducts = [...products, {productId, count: 1}];

        setProducts(newProducts);
        addProductsToLocalStorage(newProducts);
        return true;
    }, [products]);

    const deleteProduct = useMemo(() => (productId: string) => {
        const idx = products.findIndex(item => item.productId === productId);

        if (idx >= 0) {
            products.splice(idx, 1);
            setProducts((curr) => [...products]);
            addProductsToLocalStorage([...products]);
        }

        return false;
    }, [products])

    const productsPrice = useMemo(() => {
        let price = 0;

        products.forEach(item => {
            const product = getProductById(item.productId);

            if (product) {
                price += product.price * item.count;
            }
        })

        return parseFloat(price.toFixed(2));
    }, [products])


    const discount = useMemo(() => {
        let discountValue = 0;

        if (!discountData) {
            return parseFloat(discountValue.toFixed(2));
        }


        switch (discountData.type) {
            case PromoCodeType.FIXED:
                discountValue = discountData.value;
                break;
            case PromoCodeType.PERCENTAGE:
                discountValue = productsPrice * discountData.value / 100;
                break;
        }

        return parseFloat(discountValue.toFixed(2));
    }, [discountData, productsPrice]);

    const discountedProductsPrice = useMemo(() => {
        return parseFloat((productsPrice - discount).toFixed(2));
    }, [productsPrice, discount])


    const summary = useMemo(() => {
        const totalPrice = productsPrice + deliveryPrice - discount;

        return parseFloat(totalPrice.toFixed(2));
    }, [productsPrice, deliveryPrice, discount]);

    const productsCount = useMemo(() => {
        return products.reduce((sum, curr) => {
            sum += curr.count;
            return sum;
        }, 0)
    }, [products])

    const setDeliveryType = useCallback((type: DeliveryOptions) => {
        setDeliveryPrice(deliveryPriceMapper[type]);
    }, [setDeliveryPrice])

    const setPromoCode = useCallback((code?: PromoCode) => {

        if (!code) {
            setDiscountData(undefined);
            return;
        }

        if (code.type === PromoCodeType.FIXED) {
            setDiscountData(code);
            return;
        }

        const newCode: PromoCode = {...code, value: code.value % 100}

        setDiscountData(newCode);
    }, [setDiscountData])

    useEffect(() => {
        const localStorageProducts = getProductsFromLocalStorage();
        setProducts(localStorageProducts);
    }, [setProducts]);

    return (
        <CartContext.Provider
            value={{
                products,
                productsPrice,
                deliveryPrice,
                discount,
                summary,
                addProduct,
                deleteProduct,
                addSingleProduct,
                productsCount,
                setDeliveryType,
                clearCart,
                discountData,
                setPromoCode,
                discountedProductsPrice
            }}>
            {children}
        </CartContext.Provider>
    );
};

export default CartProvider;
