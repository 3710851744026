// src/context/DeviceContext.tsx
import React, { createContext, ReactNode, useContext, useEffect, useState } from 'react';
import debounce from 'lodash.debounce';

export const mediaBreakpointPx = 767;

interface DeviceContextProps {
  isMobile: boolean;
  initialized: boolean
}

const DeviceContext = createContext<DeviceContextProps | null>(null);

export const useDeviceContext = () => {
  const context = useContext(DeviceContext);
  if (!context) {
    throw new Error('useDeviceContext must be used within a DeviceProvider');
  }
  return context;
};

const DeviceProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [isMobile, setIsMobile] = useState(typeof window !== 'undefined' ? window.matchMedia(`(max-width: ${mediaBreakpointPx}px)`).matches : false);
  const [initialized, setInitialized] = useState(typeof window !== 'undefined');


  useEffect(() => {
    if (typeof window === 'undefined') {
      return;
    }

    const checkIfMobile = () => {
      const mobileCheck = window.matchMedia(`(max-width: ${mediaBreakpointPx}px)`);
      setIsMobile(mobileCheck.matches);
    };

    checkIfMobile();
    window.addEventListener('resize', checkIfMobile);
    return () => window.removeEventListener('resize', checkIfMobile);
  }, []);

  return (
    <DeviceContext.Provider value={{ isMobile, initialized }}>
      {children}
    </DeviceContext.Provider>
  );
};

export default DeviceProvider;
