import {PromoCode} from "../api/api";

export enum PaymentOptions {
    CASH ='CASH',
    CARD = 'CARD',
    ONLINE = 'ONLINE'
}

export enum DeliveryTimeOptions {
    ASAP = 'ASAP',
    DATE = 'DATE'
}

export enum DeliveryOptions {
    SELF = 'SELF',
    COURIER = 'COURIER'
}

export interface CartProduct {
    productId: string;
    count: number;
}


export type OrderForm = {
    firstName: string; // required
    email: string; // required
    address: string; // required if deliveryOptions -> COURIER
    postalCode: string; // required if deliveryOptions -> COURIER
    deliveryOption: DeliveryOptions; // required
    paymentOption: PaymentOptions; // required
    deliveryTimeOptions: DeliveryTimeOptions; // required
    dateTime: Date; // required if deliveryTimeOptions -> DATE
    callMe: boolean; //
    comment: string; //
    personsCount: number; // required min 1
    discountCode: string; //
    phone: string; // required
    deliveryMapCheckbox: boolean;
}

export type OrderDTO = Omit<OrderForm, 'dateTime'> & {
    products: CartProduct[]; // required min length 1
    estimatedPrice: number; // required
    productsPrice: number;
    discountedProductsPrice: number;
    deliveryPrice: number;
    promoCode?: PromoCode;
    dateTime?: number;
}